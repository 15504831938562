// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-flexbox-holy-grail-tsx": () => import("./../../../src/pages/flexbox/holy-grail.tsx" /* webpackChunkName: "component---src-pages-flexbox-holy-grail-tsx" */),
  "component---src-pages-flexbox-nested-1-tsx": () => import("./../../../src/pages/flexbox/nested-1.tsx" /* webpackChunkName: "component---src-pages-flexbox-nested-1-tsx" */),
  "component---src-pages-flexbox-nested-2-tsx": () => import("./../../../src/pages/flexbox/nested-2.tsx" /* webpackChunkName: "component---src-pages-flexbox-nested-2-tsx" */),
  "component---src-pages-flexbox-nested-3-tsx": () => import("./../../../src/pages/flexbox/nested-3.tsx" /* webpackChunkName: "component---src-pages-flexbox-nested-3-tsx" */),
  "component---src-pages-flexbox-nested-4-tsx": () => import("./../../../src/pages/flexbox/nested-4.tsx" /* webpackChunkName: "component---src-pages-flexbox-nested-4-tsx" */),
  "component---src-pages-flexbox-playground-tsx": () => import("./../../../src/pages/flexbox/playground.tsx" /* webpackChunkName: "component---src-pages-flexbox-playground-tsx" */),
  "component---src-pages-flexbox-properties-tsx": () => import("./../../../src/pages/flexbox/properties.tsx" /* webpackChunkName: "component---src-pages-flexbox-properties-tsx" */),
  "component---src-pages-flexbox-quiz-1-tsx": () => import("./../../../src/pages/flexbox/quiz-1.tsx" /* webpackChunkName: "component---src-pages-flexbox-quiz-1-tsx" */),
  "component---src-pages-flexbox-quiz-2-tsx": () => import("./../../../src/pages/flexbox/quiz-2.tsx" /* webpackChunkName: "component---src-pages-flexbox-quiz-2-tsx" */),
  "component---src-pages-form-layout-tsx": () => import("./../../../src/pages/form-layout.tsx" /* webpackChunkName: "component---src-pages-form-layout-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-oath-tsx": () => import("./../../../src/pages/oath.tsx" /* webpackChunkName: "component---src-pages-oath-tsx" */),
  "component---src-templates-homework-template-tsx": () => import("./../../../src/templates/homeworkTemplate.tsx" /* webpackChunkName: "component---src-templates-homework-template-tsx" */)
}

